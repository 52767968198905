<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <button class="btn btn-info" @click="handleEdit()">
            <i class="fa fa-step-backward"></i> ย้อนกลับ
          </button>
          <h4 class="card-title mt-4">รายละเอียดเว็บไซต์</h4>
        </template>
        <div>
          <form class="form-horizontal">
            <div class="row">
              <div class="col-md-3">
                <fg-input
                  label="วันที่"
                  type="text"
                  :value="result_data.createdAt"
                  disabled
                />
              </div>
              <div class="col-md-3">
                <fg-input
                  label="เครือข่าย"
                  type="text"
                  :value="result_data.group"
                  disabled
                />
              </div>
              <div class="col-md-3">
                <fg-input
                  label="เว็บไซต์"
                  type="text"
                  :value="result_data.service_name"
                  disabled
                />
              </div>
              <div class="col-md-3">
                <fg-input
                  label="สถานะ"
                  type="text"
                  :value="result_data.status"
                  disabled
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <fg-input
                label="URL Website"
                  type="text"
                  disabled
                  :value="result_data.service_url"
                />
              </div>
              <div class="col-md-6">
                <fg-input
                label="URL Cloudfront"
                  type="text"
                  disabled
                  :value="result_data.cloudfront_url"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  label="ไลน์ติดต่อ (LINE-CONNECT)"
                  type="text"
                  :value="result_data.line_connect"
                  disabled
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  label="คำแนะนำ"
                  type="text"
                  :value="result_data.comment"
                  disabled
                />
              </div>
            </div>
          </form>
        </div>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, ChartCard, StatsCard } from "src/components/index";
var jwt = require("jsonwebtoken");
export default {
  components: {
    Checkbox,
    Radio,
    ChartCard,
    StatsCard
  },
  data() {
    return {
      radios: {
        radio1: "1",
        radio2: "2",
        radio3: "2"
      },
      checkboxes: {
        first: false,
        second: false,
        a: false,
        b: false,
        c: false,
        unchecked: false,
        checked: true,
        disabledUnchecked: false,
        disabledChecked: true
      },
      pieChart: {
        data: {
          labels: [],
          series: []
        }
      },
      pieChart1: {
        data: {
          labels: ["ไม่มีข้อมูลสรุป"],
          series: [0]
        }
      },
      isChart: false,
      result_data: [],
      accept_url: "",
      click_url: "",
      platform_name: ""
    };
  },
  methods: {
    handleEdit() {
      this.$router.push("/show_site");
    }
  },
  async mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      id: this.$route.query.id
    };
    var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + user
    };
    await this.$axios
      .post(
        "/detail_service",
        { token: token },
        {
          headers: headers
        }
      )
      .then(async res => {
        if (res.data.payload && this.$route.query.id && this.$route.query.web) {
          res.data.payload.status =
            res.data.payload.status === 1 ? "ใช้งาน" : "ยกเลิก";
          this.result_data = res.data.payload;
        } else {
          this.$router.push("/");
        }
      });
  }
};
</script>
<style lang="css" scoped>
.success-color {
  background-color: #00c851 !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
}
</style>
