<template>
  <div>
    <div class="row">
      <div class="col-xl-2 col-md-6">
        <stats-card :title="ABAGROUP" subTitle="ABA-GROUP">
          <div slot="header" class="icon-warning">
            <i class="nc-icon nc-layers-3 text-warning"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-xl-2 col-md-6">
        <stats-card :title="AUTOGROUP" subTitle="AUTO-GROUP">
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-layers-3 text-success"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-xl-2 col-md-6">
        <stats-card :title="ABABACARAT" subTitle="ABA-BACARAT">
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-layers-3 text-danger"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-xl-2 col-md-6">
        <stats-card :title="BCRGROUP" subTitle="BCR-GROUP">
          <div slot="header" class="icon-info">
            <i class="nc-icon nc-layers-3 text-primary"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-xl-2 col-md-6">
        <stats-card :title="ABABACARAT" subTitle="ABA-BACARAT">
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-layers-3 text-danger"></i>
          </div>
        </stats-card>
      </div>
      <div class="col-xl-2 col-md-6">
        <stats-card :title="OTHER" subTitle="OTHER">
          <div slot="header" class="icon-warning">
            <i class="nc-icon nc-layers-3 text-warning"></i>
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-4" v-if="isChart">
        <chart-card :chart-data="pieChart.data" chart-type="Pie" class="pd-2">
          <template slot="header">
            <h4 class="card-title">ภาพรวมการดูโฆษณา</h4>
          </template>
          <template slot="footer">
            <div class="legend">
              <i class="fa fa-circle text-info"></i> Ios
              {{ this.persen[0] }}
              <i class="fa fa-circle text-danger"></i> Website
              {{ this.persen[1] }}
              <i class="fa fa-circle text-warning"></i> Android
              {{ this.persen[2] }}
            </div>
          </template>
        </chart-card>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-8" v-if="isChart">
        <chart-card
          :chart-data="barChart.data"
          :chart-options="barChart.options"
          :chart-responsive-options="barChart.responsiveOptions"
          chart-type="Bar"
        >
          <template slot="header">
            <h4 class="card-title">ภาพรวมการตอบรับ</h4>
            <p class="card-category">วันที่ : {{ date_now }}</p>
          </template>
          <template slot="footer">
            <div class="legend fxh"></div>
          </template>
        </chart-card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
} from "src/components/index";
import moment from "moment";
var jwt = require("jsonwebtoken");
export default {
  components: {
    Checkbox,
    Card,
    LTable,
    ChartCard,
    StatsCard,
  },
  data() {
    return {
      editTooltip: "Edit Task",
      deleteTooltip: "Remove",
      pieChart: {
        data: {
          labels: [" ", " ", " "],
          series: [],
        },
      },
      lineChart: {
        data: {
          labels: [""],
          series: [],
        },
        options: {
          low: 0,
          high: 800,
          showArea: false,
          height: "270px",
          axisX: {
            showGrid: false,
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      barChart: {
        data: {
          labels: [""],
          series: [[]],
        },
        options: {
          seriesBarDistance: 1,
          axisX: {
            showGrid: false,
          },
          height: "245px",
        },
        responsiveOptions: [
          [
            "screen and (max-width: 840px)",
            {
              seriesBarDistance: 1,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      isnull: 0,
      persen: [],
      isChart: false,
      ABABACARAT: "",
      ABAGROUP: "",
      AUTOGROUP: "",
      BCRGROUP: "",
      OTHER: "",
      date_now: "",
    };
  },
  async mounted() {
    this.date_now = moment().format("DD-MM-YYYY");
    let rule = JSON.parse(localStorage.getItem("user"));
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + rule,
    };
    await this.$axios
      .post("/overview", "", { headers: headers })
      .then(async (res) => {
        if (res && res.data) {
          let data_sum = [];
          let data_text = [];
          for (const [index, item] of res.data.group.entries()) {
            this.barChart.data.labels[index] = item[0].platform;
            item[0].count = item[0].count ? parseInt(item[0].count) : 0;
            data_sum.push(item[0].count);
          }
          this.barChart.data.series[0] = data_sum;
          /////////////////////////////////////////////
          this.ABABACARAT = res.data.ABABACARAT.toString();
          this.ABAGROUP = res.data.ABAGROUP.toString();
          this.AUTOGROUP = res.data.AUTOGROUP.toString();
          this.BCRGROUP = res.data.BCRGROUP.toString();
          this.OTHER = res.data.OTHER.toString();
          res.data.web = parseInt(res.data.web);
          res.data.ios = parseInt(res.data.ios);
          res.data.android = parseInt(res.data.android);
          let result_series = res.data.ios + res.data.web + res.data.android;
          this.persen[0] =
            res.data.ios > 0
              ? ((res.data.ios * 100) / result_series).toFixed(0) + "%"
              : "0%";
          this.persen[1] =
            res.data.web > 0
              ? ((res.data.web * 100) / result_series).toFixed(0) + "%"
              : "0%";
          this.persen[2] =
            res.data.android > 0
              ? ((res.data.android * 100) / result_series).toFixed(0) + "%"
              : "0%";

          this.pieChart.data.series[0] =
            res.data.ios > 0 ? (res.data.ios * 100) / result_series : "";
          this.pieChart.data.series[1] =
            res.data.web > 0 ? (res.data.web * 100) / result_series : "";
          this.pieChart.data.series[2] =
            res.data.android > 0
              ? (res.data.android * 100) / result_series
              : "";

          this.isnull =
            this.pieChart.data.series[0].length === 0 ? (this.isnull += 1) : -1;
          this.isnull =
            this.pieChart.data.series[1].length === 0 ? (this.isnull += 1) : -1;
          this.isnull =
            this.pieChart.data.series[2].length === 0 ? (this.isnull += 1) : -1;
          this.isnull = this.isnull === 3 ? true : false;
          this.isChart = true;
        }
      });
  },
};
</script>
<style>
.fxh {
  height: 19px;
}
</style>
