var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{ name: 'ABA GROUP', icon: 'nc-icon nc-grid-45' }}},_vm._l((_vm.aba_group),function(item){return _c('sidebar-item',{key:item.message,class:item.service_name == _vm.name && 'active-select',attrs:{"link":{
            name: item.service_name,
            path: '/redirect?web=' + item.service_name + '&group=ABA-GROUP'
          }},nativeOn:{"click":function($event){return _vm.clickmenu(item.service_name)}}})}),1),_c('sidebar-item',{attrs:{"link":{ name: 'AUTO GROUP', icon: 'nc-icon nc-grid-45' }}},_vm._l((_vm.auto_group),function(item){return _c('sidebar-item',{key:item.message,class:item.service_name == _vm.name && 'active-select',attrs:{"link":{
            name: item.service_name,
            path: '/redirect?web=' + item.service_name + '&group=AUTO-GROUP'
          }},nativeOn:{"click":function($event){return _vm.clickmenu(item.service_name)}}})}),1),_c('sidebar-item',{attrs:{"link":{ name: 'ABA BACARAT', icon: 'nc-icon nc-grid-45' }}},_vm._l((_vm.aba_bacarat),function(item){return _c('sidebar-item',{key:item.message,class:item.service_name == _vm.name && 'active-select',attrs:{"link":{
            name: item.service_name,
            path: '/redirect?web=' + item.service_name + '&group=ABA-BACARAT'
          }},nativeOn:{"click":function($event){return _vm.clickmenu(item.service_name)}}})}),1),_c('sidebar-item',{attrs:{"link":{ name: 'BCR GROUP', icon: 'nc-icon nc-grid-45' }}},_vm._l((_vm.bcr_group),function(item){return _c('sidebar-item',{key:item.message,class:item.service_name == _vm.name && 'active-select',attrs:{"link":{
            name: item.service_name,
            path: '/redirect?web=' + item.service_name + '&group=BCR-GROUP'
          }},nativeOn:{"click":function($event){return _vm.clickmenu(item.service_name)}}})}),1),_c('sidebar-item',{attrs:{"link":{ name: 'OTHER', icon: 'nc-icon nc-grid-45' }}},_vm._l((_vm.other),function(item){return _c('sidebar-item',{key:item.message,class:item.service_name == _vm.name && 'active-select',attrs:{"link":{
            name: item.service_name,
            path: '/redirect?web=' + item.service_name + '&group=OTHER'
          }},nativeOn:{"click":function($event){return _vm.clickmenu(item.service_name)}}})}),1),_c('hr',{staticClass:"hr"}),(_vm.rule)?_c('sidebar-item',{attrs:{"link":{
          name: 'ระบบ QR CODE',
          icon: 'nc-icon nc-bullet-list-67',
          path: '/qr_code'
        }}}):_vm._e(),(_vm.rule)?_c('sidebar-item',{attrs:{"link":{
          name: 'จัดการแพลตฟอร์ม',
          icon: 'nc-icon nc-bullet-list-67',
          path: '/show_plateform'
        }}}):_vm._e(),(_vm.rule)?_c('sidebar-item',{attrs:{"link":{
          name: 'จัดการเว็บไซต์',
          icon: 'nc-icon nc-bullet-list-67',
          path: '/show_site'
        }}}):_vm._e(),(_vm.rule)?_c('sidebar-item',{attrs:{"link":{
          name: 'จัดการพนักงาน',
          icon: 'nc-icon nc-bullet-list-67',
          path: '/member'
        }}}):_vm._e(),_c('a',{staticClass:"d-block singout ml-2 ",on:{"click":_vm.singout}},[_c('i',{staticClass:"nc-icon nc-button-power",staticStyle:{"font-size":"28px"}}),_c('p',{staticStyle:{"margin-left":"47px","margin-top":"-28px"}},[_vm._v(" ออกจากระบบ ")])])]}}])},[_c('user-menu')],1),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar($event)}}}),_c('content-footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }