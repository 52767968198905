<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <button class="btn btn-info" @click="handleEdit()">
            <i class="fa fa-step-backward"></i> ย้อนกลับ
          </button>
          <h4 class="card-title mt-4">เพิ่มข้อมูลเว็บไซต์</h4>
        </template>
        <div>
          <form class="form-horizontal">
            <div class="row">
              <div class="col-md-3">
                <fg-input
                  label="วันที่"
                  type="text"
                  :value="createdAt"
                  disabled
                />
              </div>
              <div class="col-md-7">
                <fg-input label="เว็บไซต์" type="text" v-model="service_name" />
              </div>
              <div class="col-md-2">
                <label> เครือข่าย</label><br />
                <el-select
                  label="เครือข่าย"
                  class="select-primary"
                  size="large"
                  placeholder="เลือกเครือข่าย"
                  v-model="selects.simple"
                >
                  <el-option
                    v-for="option in selects.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  label="ที่อยู่เว็บไซต์"
                  type="text"
                  v-model="service_url"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input
                  label="ไลน์ติดต่อ (Line-connect)"
                  type="text"
                  v-model="lineconnect"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <fg-input label="คำแนะนำ" type="text" v-model="comment" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <l-button type="success" wide @click="handleSave()"
                  >เพื่มข้อมูล</l-button
                >
              </div>
            </div>
          </form>
        </div>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, ChartCard, StatsCard } from "src/components/index";
import { Select, Option } from "element-ui";
import Swal from "sweetalert2";
import moment from "moment";
var jwt = require("jsonwebtoken");
export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    Checkbox,
    Radio,
    ChartCard,
    StatsCard
  },
  data() {
    return {
      selects: {
        simple: "",
        countries: [
          {
            label: "ABA-GROUP",
            value: "ABA-GROUP"
          },
          {
            label: "AUTO-GROUP",
            value: "AUTO-GROUP"
          },
          {
            label: "ABA-BACARAT",
            value: "ABA-BACARAT"
          },
          {
            label: "BCR-GROUP",
            value: "BCR-GROUP"
          },
          {
            label: "OTHER",
            value: "OTHER"
          }
        ]
      },
      createdAt: "",
      comment: "",
      service_url: "",
      service_name: "",
      lineconnect: ""
    };
  },
  methods: {
    handleEdit() {
      this.$router.push("/show_site");
    },
    async handleSave() {
      if (
        this.service_name &&
        this.service_url &&
        this.lineconnect &&
        this.selects.simple
      ) {
        let selects = this.selects.simple
          ? this.selects.simple
          : this.result_data.group;

        let payload = {
          id: this.$route.query.id,
          service_name: this.service_name,
          service_url: this.service_name,
          group: selects,
          comment: this.comment,
          line_connect: this.lineconnect
        };
        let user = JSON.parse(localStorage.getItem("user"));
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user
        };
        await this.$axios
          .post("/create_site", { token: token }, { headers: headers })
          .then(res => {
            if (res.data.code === 0) {
              Swal.fire({
                title: res.data.msg,
                icon: "success",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true
              });
            } else {
              Swal.fire({
                title: res.data.msg,
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true
              });
            }
          })
          .catch(() => {});
      } else {
        Swal.fire({
          title: "กรอกข้อมูลให้ครบ",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true
        });
      }
    }
  },
  async mounted() {
    this.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
  }
};
</script>
<style lang="css" scoped>
.success-color {
  background-color: #00c851 !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
}
</style>
