<template>
  <div class="user pl-4">
    <div class="photo mt-3">
      <img :src="image_profile" alt="avatar" />
    </div>
    <div class="info">
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        href="#"
      >
        <span class="mb-2">
          {{ name }}
        </span>
        <span style="font-size: 80%;font-weight: 400;">
          ตำแหน่ง :
          {{ rang }}
        </span>
      </a>
      <div class="clearfix"></div>
      <div>
        <el-collapse-transition>
          <ul class="nav" v-show="!isClosed"></ul>
        </el-collapse-transition>
      </div>
    </div>
  </div>
</template>
<script>
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import { mapActions, mapGetters } from "vuex";
var jwt = require("jsonwebtoken");

export default {
  components: {
    [CollapseTransition.name]: CollapseTransition
  },
  computed: {
    ...mapGetters({
      isLogin: "isLogin",
      user: "user"
    })
  },
  data() {
    return {
      img_user: "../static/img/default-avatar.png",
      isClosed: true,
      image_profile: "",
      name: "",
      rang: ""
    };
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    }
  },
  mounted() {
    let result = JSON.parse(localStorage.getItem("user"));
    let username = jwt.verify(result, "ABAsecretKeywweqwlinkmanager");
    this.image_profile = username.result[0].Line_image;
    this.name = username.result[0].Name;
    this.rang = username.result[0].DeptName;
  }
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
