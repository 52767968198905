import Vue from "vue";
import Vuex from "vuex";
import VueSession from "vue-session";

Vue.use(VueSession);
Vue.use(Vuex);

const state = {
  isLogin: false,
  user: [],
  rule_user:'',
  code:'',
};

const getters = {
  isLogin: state => {
    return state.isLogin;
  },
  user: state => {
    return state.user;
  }
};

const mutations = {
  isLogin: (state, payload) => {
    state.isLogin = payload;
  },
  user: (state, payload) => {
    state.user = payload;
  }
};

const actions = {
  login: ({ commit }, payload) => {
    commit("isLogin", true);
  },
  logout: ({ commit }) => {
    commit("isLogin", false);
    commit("user", null);
  }
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});
