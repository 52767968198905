<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <button class="btn btn-info" @click="handleEdit()">
            <i class="fa fa-step-backward"></i> ย้อนกลับ
          </button>
          <h4 class="card-title mt-4">เพิ่มข้อมูลสมาชิก</h4>
        </template>
        <div>
          <form class="form-horizontal" @submit.prevent="handleSave()">
            <div class="row">
              <div class="col-md-4">
                <fg-input
                  label="เบอร์โทรศัพท์"
                  type="number"
                  v-model="phone_number"
                />
              </div>
              <div class="col-md-4">
                <fg-input label="ชื่อพนักงาน" type="text" v-model="employee" />
              </div>
              <div class="col-md-2">
                <label> ตำแหน่ง</label><br />
                <el-select
                  label="แพลตฟอร์ม"
                  class="select-primary"
                  size="large"
                  placeholder="เลือกตำแหน่ง"
                  v-model="selects.simple"
                >
                  <el-option
                    v-for="option in selects.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-2">
                <label> สิทธิ์การมองเห็นข้อมูล</label><br />

                <el-select
                  label="สิทธิ์การมองเห็น"
                  class="select-primary"
                  size="large"
                  placeholder="สิทธิ์การมองเห็น"
                  v-model="selectsRole.simple"
                >
                  <el-option
                    v-for="option in selectsRole.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="col-md-12">
              <label> สิทธิ์การมองเห็นเว็บ</label><br />
              <hr />
              <div class="form-check form-check-inline card-body">
                <label class="form-check-label">
                  <input
                    v-model="checkAll"
                    @change="testCheckAll()"
                    class="form-check-input"
                    type="checkbox"
                    value="true"
                  />
                  <span class="form-check-sign"></span>
                  ทั้งหมด
                </label>
              </div>

              <div
                class="form-check form-check-inline card-body"
                v-for="(item, index) in serviceAll"
                :key="index"
              >
                <label class="form-check-label">
                  <input
                    v-model="serviceSelect"
                    class="form-check-input"
                    type="checkbox"
                    :value="item"
                  />
                  <span class="form-check-sign"></span>
                  {{ item }}
                </label>
              </div>
              <hr />
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <button type="submit" class="success btn btn-success" wide>
                  บันทึกข้อมูล</button
                >&nbsp;
              </div>
            </div>
          </form>
        </div>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, ChartCard, StatsCard } from "src/components/index";
import moment from "moment";
import {
  DatePicker,
  TimeSelect,
  Slider,
  Tag,
  Input,
  Button,
  Select,
  Option,
  CheckboxGroup,
} from "element-ui";
import Swal from "sweetalert2";
var jwt = require("jsonwebtoken");
export default {
  components: {
    Checkbox,
    Radio,
    ChartCard,
    StatsCard,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  data() {
    return {
      checkAll: false,
      serviceAll: [],
      serviceSelect: [],
      phone_number: "",
      employee: "",
      rang: "",
      selects: {
        simple: "",
        countries: [
          {
            label: "EMPLOYEE",
            value: "1",
            selected: true,
          },
          {
            label: "ADMIN",
            value: "2",
          },
        ],
      },
      selectsWeb: {
        simple: "",
        countries: [
          {
            label: "ทั้งหมด",
            value: 0,
            selected: true,
          },
          {
            label: "เลือกเว็บ",
            value: 1,
          },
        ],
      },
      selectsRole: {
        simple: "",
        countries: [
          {
            label: "ทั้งหมด",
            value: 0,
            selected: true,
          },
          {
            label: "เฉพาะของตัวเอง",
            value: 1,
          },
        ],
      },
    };
  },
  methods: {
    testCheckAll() {
      if (this.checkAll) {
        this.serviceAll.forEach((element) => {
          this.serviceSelect.push(element);
        });
      } else {
        this.serviceSelect = [];
      }

      console.log(this.checkAll, this.serviceSelect);
    },
    handleEdit() {
      this.$router.push("/member");
    },
    async handleSave() {
      if (
        (this.phone_number.length == 10 &&
          (this.phone_number.substring(0, 2) === "08" ||
            this.phone_number.substring(0, 2) === "09" ||
            this.phone_number.substring(0, 2) === "06")) ||
        this
      ) {
        if (
          this.selects.simple &&
          this.employee &&
          this.serviceSelect.length > 0
        ) {
          let serviceString = "";
          this.serviceSelect.forEach((element) => {
            serviceString += element + ",";
          });
          serviceString = serviceString.substring(0, serviceString.length - 1);
          let payload = {
            Phone_number: this.phone_number,
            Name: this.employee,
            DeptOID: this.selects.simple,
            DeptName: this.selects.simple === "2" ? "ADMIN" : "EMPLOYEE",
            RoleOID: this.selects.simple,
            service: serviceString,
            read_only: this.selectsRole.simple,
          };
          let user = JSON.parse(localStorage.getItem("user"));
          var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
          let headers = {
            "Content-Type": "application/json",
            Authorization: "authorization " + user,
          };
          await this.$axios
            .post("/add_member", { token: token }, { headers: headers })
            .then((res) => {
              if (res.data.code === 0) {
                Swal.fire({
                  title: "สมัครสมาชิก สำเร็จ!",
                  text: res.data.payload.key,
                  icon: "success",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: true,
                });
              } else {
                Swal.fire({
                  title: res.data.msg,
                  icon: "error",
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: true,
                });
              }
            })
            .catch(() => {});
        } else {
          Swal.fire({
            title: "กรอกข้อมูลให้ครบ!",
            icon: "error",
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: true,
          });
        }
      } else {
        Swal.fire({
          title: "กรอกเบอร์โทรศัพท์ให้ถูกต้อง!",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true,
        });
      }
    },
  },
  async mounted() {
    let id_user = JSON.parse(localStorage.getItem("user"));
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + id_user,
    };
    await this.$axios
      .get("/list_serviceAll", { headers: headers })
      .then(async (res) => {
        if (res.data.code == 0) {
          for (const [index, item] of res.data.payload.entries()) {
            this.serviceAll.push(item.service_name);
          }
        } else {
          this.serviceAll = [];
        }
      });
  },
};
</script>
<style>
.success-color {
  background-color: #00c851 !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
}
.extended-forms .el-select {
  width: 100%;
  margin-bottom: 30px;
}
.el-select {
  position: relative !important;
  display: contents !important;
}
.el-input {
  width: 100% !important;
}
</style>
