<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <button class="btn btn-info" @click="handleEdit()">
            <i class="fa fa-step-backward"></i> ย้อนกลับ
          </button>
          <h4 class="card-title mt-4">รายละเอียดการโฆษณา</h4>
        </template>
        <div>
          <div class="row">
            <div class="col-md-3">
              <fg-input label="วันที่" type="text" :value="result_data.datetime" disabled />
            </div>
            <div class="col-md-6">
              <fg-input label="แคมเปญหลัก" type="text" :value="result_data.main_campaign" disabled />
            </div>
            <div class="col-md-3">
              <fg-input label="แคมเปญย่อย" type="text" :value="result_data.second_campaign" disabled />
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <fg-input label="รหัสแคมเปญ" type="text" disabled :value="result_data.path" />
            </div>
            <div class="col-md-3">
              <fg-input label="พนักงานรับผิดชอบ" type="text" :value="result_data.employee" disabled />
            </div>
            <div class="col-md-3">
              <fg-input label="แพลตฟอร์ม" type="text" :value="platform_name" disabled />
            </div>
            <div class="col-md-3">
              <fg-input label="ช่องทางโฆษณา" type="text" :value="channel" disabled />
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <fg-input label="ลิ้งค์การตลาด" type="text" :value="result_data.full_url" disabled />
            </div>
            <div class="col-md-6 col-sm-12 col-xl-6">
              <fg-input label="ลิ้งค์แคมเปญ" type="text" :value="result_data.short_url" disabled />
            </div>
            <div class="col-md-2 col-sm-12 pt-4  col-xl-2">
              <button class="btn btn-success w-100 text-nowrap" v-clipboard:copy="result_data.short_url"
                v-clipboard:success="handleCopy" style="margin-top:-2px">
                คัดลอกลิ้งค์
              </button>
            </div>
          </div>
          <!-- <div class="row">
            <div class="col-md-12">
              <fg-input label="หมายเหตุ" type="text" :value="result_data.comment" disabled />
            </div>
          </div> -->
          <div class="row p-3" v-if="isChart">
            <div class="col-xl-3 col-md-3">
              <stats-card :title="interest_url" subTitle="คนดูทั้งหมด">
                <div slot="header" class="icon-warning">
                  <i class="nc-icon nc-align-left-2 text-warning"></i>
                </div>
              </stats-card>
            </div>

            <div class="col-xl-3 col-md-3">
              <stats-card :title="click_url" subTitle="คนสนใจทั้งหมด">
                <div slot="header" class="icon-success">
                  <i class="nc-icon nc-align-left-2 text-success"></i>
                </div>
              </stats-card>
            </div>

            <div class="col-xl-3 col-md-3">
              <stats-card :title="register" subTitle="คนที่สมัครสมาชิกทั้งหมด">
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-align-left-2 text-danger"></i>
                </div>
              </stats-card>
            </div>
            <div class="col-xl-3 col-md-3">
              <stats-card :title="register_deposit" subTitle="สมาชิกที่ฝากเงินทั้งหมด">
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-align-left-2 text-info"></i>
                </div>
              </stats-card>
            </div>
            <div class="col-xl-4 col-md-4">
              <stats-card :title="agv_data[0]" subTitle="คนที่สนใจ ( % )">
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-chart-bar-32 text-success"></i>
                </div>
              </stats-card>
            </div>
            <div class="col-xl-4 col-md-4">
              <stats-card :title="agv_data[1]" subTitle="คนที่สมัคร ( % )">
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-chart-bar-32 text-success"></i>
                </div>
              </stats-card>
            </div>
            <div class="col-xl-4 col-md-4">
              <stats-card :title="agv_data[2]" subTitle="สมาชิกที่ฝากเงิน ( % )">
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-chart-bar-32 text-success"></i>
                </div>
              </stats-card>
            </div>
            <div class="col-xl-3 col-md-3">
              <stats-card :title="firstdeposit" subTitle="เงินฝากครั้งแรก">
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-money-coins text-success"></i>
                </div>
              </stats-card>
            </div>
            <div class="col-xl-3 col-md-3">
              <stats-card :title="secondedeposit" subTitle="เงินฝากครั้งที่ 2">
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-money-coins text-success"></i>
                </div>
              </stats-card>
            </div>
            <div class="col-xl-3 col-md-3">
              <stats-card :title="sumtotal" subTitle="เงินฝากทั้งหมด">
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-money-coins text-success"></i>
                </div>
              </stats-card>
            </div>
            <div class="col-xl-3 col-md-3">
              <stats-card :title="sumtotalwithdraw" subTitle="เงินถอนทั้งหมด">
                <div slot="header" class="icon-danger">
                  <i class="nc-icon nc-money-coins text-success"></i>
                </div>
              </stats-card>
            </div>
          </div>

          <div class="row p-3" v-if="isChart">
            <chart-card :chart-data="barChart.data" :chart-options="barChart.options"
              :chart-responsive-options="barChart.responsiveOptions" chart-type="Bar"
              class="col-md-12 col-sm-12 col-xl-12">
              <template slot="header">
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-xl-6">
                    <h5 class="card-title" style="margin-top: 10px">
                      สรุปสมัครสมาชิก {{ selectday }} ย้อนหลัง
                    </h5>
                  </div>
                  <div class="col-md-3 col-sm-12 col-xl-3 text-rigth"></div>
                  <div class="col-md-3 col-sm-12 col-xl-3 text-rigth">
                    <mdb-select v-model="select_day" :options="select_day" wrapperStyle="" placeholder="ตัวเลือก"
                      label="" @getValue="handleselectday" style="margin-top: -25px" />
                  </div>
                </div>
              </template>
              <template slot="footer">
                <div class="legend">
                  <i class="fa fa-circle text-info"></i> สมัครสมาชิก
                  <i class="fa fa-circle text-danger"></i>
                  สมาชิกที่ฝากเงินฝากครั้งแรก
                  <i class="fa fa-circle text-warning"></i>
                  สมาชิกที่ฝากเงินฝากครั้งที่ 2
                </div>
              </template>
            </chart-card>
          </div>
          <div class="row" v-if="isChart">
            <div class="col-xs-12 col-md-6 col-sm-12 col-xl-6">
              <mdb-card>
                <h5 class="card-title mt-4 ml-3 text-center">พฤติกรรมคนที่สนใจ</h5>
                <mdb-doughnut-chart :data="doughnutChartData" :options="doughnutChartOptions">
                </mdb-doughnut-chart>
              </mdb-card>
            </div>
            <div class="col-xs-12 col-md-6 col-sm-12 col-xl-6">
              <div v-if="!checkIsnull">
                <chart-card :chart-data="pieChart.data" class="text-center" chart-type="Pie">
                  <template slot="header">
                    <h4 class="card-title">สรุปการดูโฆษณา</h4>
                    <p class="card-category"></p>
                  </template>
                  <template slot="footer">
                    <div class="legend">
                      <i class="fa fa-circle text-info"></i> Ios
                      {{ this.persen[0] }}
                      <i class="fa fa-circle text-danger"></i> Website
                      {{ this.persen[1] }}
                      <i class="fa fa-circle text-warning"></i> Android
                      {{ this.persen[2] }}
                    </div>
                  </template>
                </chart-card>
              </div>
              <div v-else>
                <chart-card :chart-data="pieChart1.data" class="text-center" chart-type="Pie">
                  <template slot="header">
                    <h4 class="card-title">สรุปการดูโฆษณา</h4>
                    <p class="card-category"></p>
                  </template>
                  <template slot="footer">
                    <div class="legend">
                      <i class="fa fa-circle text-info"></i> Ios
                      <i class="fa fa-circle text-danger"></i> Website
                      <i class="fa fa-circle text-warning"></i> Android
                    </div>
                  </template>
                </chart-card>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-sm-12 col-xl-12" v-if="isChart">
          <div class="row">
            <div class="col-sm-12 col-md-5">
              <h5 class="card-title ml-2">รายละเอียดคนสมัครสมาชิก</h5>
              <b class="ml-3" style="color: red; font-size: 13px">เริ่มเก็บข้อมูล การถอนเงินตั้งแต่ วันที่ 19 สิงหาคม
                2564 เป็นต้นไป</b>
            </div>
            <div class="mb-2">
              <div class="align-self-center pl-3">
                ข้อมูลจากวันที่
              </div>
              <div class="align-self-center col-sm-12 col-md-2">
                <date-picker class="input-date" valueType="format" lang="en" v-model="date_start"></date-picker>
              </div>
            </div>
            <div class="mb-2">
              <div class="align-self-center pl-3">
                ข้อมูลถึงวันที่
              </div>
              <div class="align-self-center col-sm-12 col-md-2">
                <date-picker class="input-date" valueType="format" lang="en" v-model="date_end"></date-picker>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 mt-2 text-center">
              <button class="text-nowrap btn btn-success" type="success" @click="handleFind()">
                ค้นหา
              </button>
              <button class="text-nowrap btn btn-info" type="info" @click="handlereset()">
                ล้างข้อมูล
              </button>
            </div>
          </div>
          <mdb-datatable :data="data" striped bordered responsive />
        </div>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, ChartCard, StatsCard } from "src/components/index";
import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import {
  mdbSelect,
  mdbDoughnutChart,
  mdbBarChart,
  mdbCard,
  mdbDatatable,
  mdbCardImage,
  mdbCardBody,
  mdbCardTitle,
  mdbCardText
} from "mdbvue";
import moment, { parseZone } from "moment";
var jwt = require("jsonwebtoken");
let no = 0;
export default {
  components: {
    DatePicker,
    Checkbox,
    Radio,
    ChartCard,
    StatsCard,
    mdbSelect,
    mdbDoughnutChart,
    mdbBarChart,
    mdbCard,
    ChartCard,
    mdbDatatable,
    mdbCardImage,
    mdbCardBody,
    mdbCardTitle,
    mdbCardText
  },
  data() {
    return {
      doughnutChartData: {
        labels: ["คนสนใจ", "คนสมัครสมาชิก", "สมาชิกที่ผากเงิน"],
        datasets: [
          {
            data: [],
            backgroundColor: ["#28a745", "#F7464A", "#33b5e5"],
            hoverBackgroundColor: ["#28a745", "#F7464A", "#33b5e5"]
          }
        ]
      },
      doughnutChartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      radios: {
        radio1: "1",
        radio2: "2",
        radio3: "2"
      },
      agv_data: [],
      pieChart: {
        data: {
          labels: [" ", " ", " "],
          series: []
        }
      },
      pieChart1: {
        data: {
          labels: ["ไม่มีข้อมูลสรุป"],
          series: [0]
        }
      },
      isChart: false,
      isnull: 0,
      checkIsnull: false,
      persen: [],
      result_data: [],
      accept_url: "",
      click_url: "",
      platform_name: "",
      channel: "",
      page_width: 0,
      barChart: {
        data: {
          labels: [],
          series: [[], [], []]
        },
        options: {
          seriesBarDistance: 12,
          axisX: {
            showGrid: false
          },
          height: "245px"
        },
        responsiveOptions: [
          [
            "screen and (max-width:100%)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      id_campaign: "",
      compaign_name: "",
      date_now: "",
      firstdeposit: "",
      secondedeposit: "",
      sumtotal: "",
      sumtotalwithdraw: "",
      sumtotalwithdraws: "",
      sumprofit: "",
      select_day: [
        { text: "7 วัน", value: "7" },
        { text: "14 วัน", value: "14" },
        { text: "30 วัน", value: "30" }
      ],
      selectday: "7 วัน",
      data: {
        columns: [
          {
            label: "ลำดับ",
            sort: "asc",
            format: () => {
              no += 1;
              return no;
            }
          },
          {
            label: "วันที่สมัคร",
            field: "date_regis",
            sort: "asc"
          },
          {
            label: "ยูสเซอร์เนมลูกค้า",
            field: "username",
            sort: "asc"
          },
          {
            label: "วันที่ฝากครั้งแรก",
            field: "datetime_deposit",
            sort: "asc"
          },
          {
            label: "ฝากครั้งแรก",
            field: "deposit",
            sort: "asc"
          },
          {
            label: "วันที่ฝากครั้งต่อมา",
            field: "datetime_second",
            sort: "asc"
          },
          {
            label: "ฝากครั้งต่อมา",
            field: "second_deposite",
            sort: "asc"
          },
          {
            label: "ฝากเงินทั้งหมด",
            field: "total_deposit",
            sort: "asc"
          },
          {
            label: "ถอนเงินทั้งหมด",
            field: "withdraw",
            sort: "asc"
          },
          {
            label: "กำไร",
            sort: "asc",
            field: "profit",
            format: value => {
              if (value < 0) {
                return "<b style='color: red'>" + value + "</b>";
              } else {
                return "<b style='color: green'>" + value + "</b>";
              }
            }
          }
        ],
        rows: []
      },
      date_start: "",
      date_end: "",
    };
  },
  methods: {
    handleEdit() {
      this.$router.push("/redirect?web=" + this.$route.query.web);
    },
    handleCopy() {
      Swal.fire({
        title: "คัดลอกลิ้งเรียบร้อย",
        text: this.result_data.short_url,
        icon: "success",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true
      });
    },
    async handleselect(value, text) {
      no = 0;
      this.isChart = false;
      this.date_now = moment().format("DD-MM-YYYY");
      if (text) {
        this.barChart.data.labels = [];
        this.barChart.data.series[0] = [];
        this.barChart.data.series[1] = [];
        this.barChart.data.series[2] = [];
        this.id_campaign = value;
        this.compaign_name = text;
        let payload = {
          id: value
        };
        let user = JSON.parse(localStorage.getItem("user"));
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user
        };
        await this.$axios
          .post("/detail_report", { token: token }, { headers: headers })
          .then(async res => {
            if (res && res.data.code === 0) {
              let check_deposit = 0;
              let result_log = [];
              let firstdeposits = 0;
              let secondedeposits = 0;
              let sumtotals = 0;
              let sumtotalwithdraws = 0;

              for (const item of res.data.log) {
                item.total_deposit = item.total_deposit ? item.total_deposit : 0;
                item.second_deposite = item.second_deposite ? item.second_deposite : 0;
                item.deposit = item.deposit ? item.deposit : 0;
                item.datetime_deposit = item.datetime_deposit ? item.datetime_deposit : "";
                item.datetime_second = item.datetime_second ? item.datetime_second : "";

                firstdeposits +=
                  firstdeposits + item.deposit ? item.deposit : 0;
                secondedeposits +=
                  secondedeposits + item.second_deposite
                    ? item.second_deposite
                    : 0;
                sumtotals +=
                  sumtotals + item.total_deposit ? item.total_deposit : 0;
                sumtotalwithdraws +=
                  sumtotalwithdraws + item.withdraw ? item.withdraw : 0;
                item.profit = item.total_deposit - item.withdraw;
                result_log.push(item);
              }
              this.firstdeposit = parseFloat(firstdeposits)
                .toFixed(2)
                .toString();
              this.sumtotal =
                sumtotals > 0
                  ? parseFloat(sumtotals)
                    .toFixed(2)
                    .toString()
                  : "0";
              this.sumtotalwithdraw =
                sumtotalwithdraws > 0
                  ? parseFloat(sumtotalwithdraws)
                    .toFixed(2)
                    .toString()
                  : "0";
              this.sumprofit =
                this.sumtotal -
                this.sumtotalwithdraw +
                " ( " +
                (
                  ((this.sumtotal - this.sumtotalwithdraw) * 100) /
                  this.sumtotal
                ).toFixed(2) +
                "% )";
              this.secondedeposit = parseFloat(secondedeposits)
                .toFixed(2)
                .toString();
              this.data.rows = result_log;
              res.data.log.map(function (e) {
                if (e.deposit != null) {
                  check_deposit += 1;
                }
              });
              this.interest_url = res.data.tracking
                ? res.data.tracking.interest_url === 0
                  ? "-"
                  : res.data.tracking.interest_url.toString()
                : "-";
              this.click_url = res.data.tracking
                ? res.data.tracking.click_url === 0
                  ? "-"
                  : res.data.tracking.click_url.toString()
                : "-";
              this.register = res.data.log
                ? res.data.log.length === 0
                  ? "-"
                  : res.data.log.length.toString()
                : "-";
              this.register_deposit = res.data.log
                ? check_deposit === 0
                  ? "-"
                  : check_deposit.toString()
                : "-";

              this.agv_data[0] =
                (this.click_url * 100) / this.interest_url
                  ? parseFloat(
                    (this.click_url * 100) / this.interest_url
                  ).toFixed(2)
                  : "-";
              this.agv_data[1] =
                (this.register * 100) / this.click_url
                  ? parseFloat((this.register * 100) / this.click_url).toFixed(
                    2
                  )
                  : "-";
              this.agv_data[2] =
                (this.register_deposit * 100) / this.register
                  ? parseFloat(
                    (this.register_deposit * 100) / this.register
                  ).toFixed(2)
                  : "-";
              this.doughnutChartData.datasets[0].data[2] =
                this.register_deposit === "-" ? 0 : this.register_deposit;
              this.doughnutChartData.datasets[0].data[1] =
                this.register === "-" ? 0 : this.register;
              this.doughnutChartData.datasets[0].data[0] =
                parseInt(this.click_url === "-" ? 0 : this.click_url) -
                parseInt(this.doughnutChartData.datasets[0].data[1]) -
                parseInt(this.doughnutChartData.datasets[0].data[2]);


              let days = [];
              let amount_regis = [];
              for (let i = 6; i >= 0; i--) {
                let check_deposit = 0;
                let check_memberdeposit = 0;
                let check_memberdepositsecond = 0;
                res.data.log.map(function (e) {
                  if (
                    moment(e.date_regis).format("YYYY-MM-DD") ===
                    moment()
                      .add(-i, "days")
                      .format("YYYY-MM-DD")
                  ) {
                    check_deposit += 1;
                  }

                  if (
                    moment(e.datetime_deposit).format("YYYY-MM-DD") ===
                    moment()
                      .add(-i, "days")
                      .format("YYYY-MM-DD") &&
                    e.deposit > 0
                  ) {
                    check_memberdeposit += 1;
                  }

                  if (
                    moment(e.datetime_second).format("YYYY-MM-DD") ===
                    moment()
                      .add(-i, "days")
                      .format("YYYY-MM-DD") &&
                    e.second_deposite > 0
                  ) {
                    check_memberdepositsecond += 1;
                  }
                });

                this.barChart.data.series[0][6 - i] = check_deposit;
                this.barChart.data.series[1][6 - i] = check_memberdeposit;
                this.barChart.data.series[2][6 - i] = check_memberdepositsecond;
                days.push(
                  moment()
                    .add(-i, "days")
                    .format("DD")
                );
              }
              this.barChart.data.labels = days;
            }
          });
        this.isChart = true;
      } else {
        this.isChart = false;
      }
    },
    async handleselectday(value, text) {
      this.selectday = text;
      if (text) {
        this.barChart.data.labels = [];
        this.barChart.data.series[0] = [];
        this.barChart.data.series[1] = [];
        this.barChart.data.series[2] = [];
        this.isChart = false;
        let user = JSON.parse(localStorage.getItem("user"));
        let payload = {
          id: this.id_campaign,
          date_start: moment()
            .add(-value, "days")
            .format("YYYY-MM-DD"),
          date_end: moment()
            .add(1, "days")
            .format("YYYY-MM-DD")
        };
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user
        };
        await this.$axios
          .post("/detail_report", { token: token }, { headers: headers })
          .then(async res => {
            if (res && res.data.code === 0) {
              let check_deposit = 0;
              let result_log = [];
              this.firstdeposit = 0;
              this.secondedeposit = 0;
              this.sumtotal = 0;
              this.sumtotalwithdraw = 0;
              let firstdeposits = 0;
              let secondedeposits = 0;
              let sumtotals = 0;
              this.sumtotalwithdraws = 0;
              for (const item of res.data.log) {
                firstdeposits +=
                  firstdeposits + item.deposit ? item.deposit : 0;
                secondedeposits +=
                  secondedeposits + item.second_deposite
                    ? item.second_deposite
                    : 0;
                sumtotals +=
                  sumtotals + item.total_deposit ? item.total_deposit : 0;
                this.sumtotalwithdraws +=
                  this.sumtotalwithdraws + item.withdraw ? item.withdraw : 0;
                item.profit = item.total_deposit - item.withdraw;
                result_log.push(item);
              }
              this.firstdeposit = parseFloat(firstdeposits)
                .toFixed(2)
                .toString();
              this.sumtotal =
                sumtotals > 0
                  ? parseFloat(sumtotals)
                    .toFixed(2)
                    .toString()
                  : "0";
              this.sumtotalwithdraw =
                this.sumtotalwithdraws > 0
                  ? parseFloat(this.sumtotalwithdraws)
                    .toFixed(2)
                    .toString()
                  : "0";
              this.sumprofit = "กำไร";
              this.secondedeposit = parseFloat(secondedeposits)
                .toFixed(2)
                .toString();
              this.data.rows = result_log;
              res.data.log.map(function (e) {
                if (e.deposit != null) {
                  check_deposit += 1;
                }
              });
              this.interest_url = res.data.tracking
                ? res.data.tracking.interest_url === 0
                  ? "-"
                  : res.data.tracking.interest_url.toString()
                : "-";
              this.click_url = res.data.tracking
                ? res.data.tracking.click_url === 0
                  ? "-"
                  : res.data.tracking.click_url.toString()
                : "-";
              this.register = res.data.log
                ? res.data.log.length === 0
                  ? "-"
                  : res.data.log.length.toString()
                : "-";
              this.register_deposit = res.data.log
                ? check_deposit === 0
                  ? "-"
                  : check_deposit.toString()
                : "-";

              this.agv_data[0] =
                (this.click_url * 100) / this.interest_url
                  ? parseFloat(
                    (this.click_url * 100) / this.interest_url
                  ).toFixed(2)
                  : "-";
              this.agv_data[1] =
                (this.register * 100) / this.click_url
                  ? parseFloat((this.register * 100) / this.click_url).toFixed(
                    2
                  )
                  : "-";
              this.agv_data[2] =
                (this.register_deposit * 100) / this.register
                  ? parseFloat(
                    (this.register_deposit * 100) / this.register
                  ).toFixed(2)
                  : "-";
              this.doughnutChartData.datasets[0].data[2] =
                this.register_deposit === "-" ? 0 : this.register_deposit;
              this.doughnutChartData.datasets[0].data[1] =
                this.register === "-" ? 0 : this.register;
              this.doughnutChartData.datasets[0].data[0] =
                parseInt(this.click_url === "-" ? 0 : this.click_url) -
                parseInt(this.doughnutChartData.datasets[0].data[1]) -
                parseInt(this.doughnutChartData.datasets[0].data[2]);
              let days = [];
              let amount_regis = [];
              for (let i = value - 1; i >= 0; i--) {
                let check_deposit = 0;
                let check_memberdeposit = 0;
                let check_memberdepositsecond = 0;
                res.data.log.map(function (e) {
                  if (
                    moment(e.date_regis).format("YYYY-MM-DD") ===
                    moment()
                      .add(-i, "days")
                      .format("YYYY-MM-DD")
                  ) {
                    check_deposit += 1;
                  }

                  if (
                    moment(e.datetime_deposit).format("YYYY-MM-DD") ===
                    moment()
                      .add(-i, "days")
                      .format("YYYY-MM-DD") &&
                    e.deposit > 0
                  ) {
                    check_memberdeposit += 1;
                  }

                  if (
                    moment(e.datetime_second).format("YYYY-MM-DD") ===
                    moment()
                      .add(-i, "days")
                      .format("YYYY-MM-DD") &&
                    e.second_deposite > 0
                  ) {
                    check_memberdepositsecond += 1;
                  }
                });
                // res.data.log.map(function (e) {
                //   if (
                //     moment(e.date_regis).format("YYYY-MM-DD") ===
                //       moment().add(-i, "days").format("YYYY-MM-DD") &&
                //     e.deposit > 0
                //   ) {
                //     check_memberdeposit += 1;
                //   }
                // });
                this.barChart.data.series[0][value - 1 - i] = check_deposit;
                this.barChart.data.series[1][
                  value - 1 - i
                ] = check_memberdeposit;
                this.barChart.data.series[2][6 - i] = check_memberdepositsecond;
                days.push(
                  moment()
                    .add(-i, "days")
                    .format("DD")
                );
              }
              this.barChart.data.labels = days;
            }
          });
        this.isChart = true;
      } else {
        this.isChart = false;
      }
    },
    async handleFind() {
      if (
        this.date_start != "" &&
        this.date_start != null &&
        this.date_end != "" &&
        this.date_end != null
      ) {
        this.date_start = moment(this.date_start).format("YYYY-MM-DD");
        this.date_end = moment(this.date_end).format("YYYY-MM-DD");
        var start = moment(this.date_start).format("YYYY-MM-DD");
        var end = moment(this.date_end).format("YYYY-MM-DD");

        if (start > end) {
          this.date_end = start;
          this.date_start = end;
        }
        no = 0;
        this.isChart = false;
        this.date_now = moment().format("DD-MM-YYYY");
        if (this.compaign_name) {
          this.barChart.data.labels = [];
          this.barChart.data.series[0] = [];
          this.barChart.data.series[1] = [];
          this.barChart.data.series[2] = [];
          let payload = {
            id: this.id_campaign,
            date_start: this.date_start,
            date_end: moment(this.date_end)
              .add(1, "days")
              .format("YYYY-MM-DD")
          };
          let user = JSON.parse(localStorage.getItem("user"));
          var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
          let headers = {
            "Content-Type": "application/json",
            Authorization: "authorization " + user
          };
          await this.$axios
            .post("/detail_report", { token: token }, { headers: headers })
            .then(async res => {
              if (res && res.data.code === 0) {
                this.result_data = res.data.tracking;
                let check_deposit = 0;
                let result_log = [];
                let firstdeposits = 0;
                let secondedeposits = 0;
                let sumtotals = 0;
                let sumtotalwithdraws = 0;

                for (const item of res.data.log) {
                  firstdeposits +=
                    firstdeposits + item.deposit ? item.deposit : 0;
                  secondedeposits +=
                    secondedeposits + item.second_deposite
                      ? item.second_deposite
                      : 0;
                  sumtotals +=
                    sumtotals + item.total_deposit ? item.total_deposit : 0;
                  sumtotalwithdraws +=
                    sumtotalwithdraws + item.withdraw ? item.withdraw : 0;
                  item.profit = item.total_deposit - item.withdraw;
                  result_log.push(item);
                }

                this.firstdeposit = parseFloat(firstdeposits)
                  .toFixed(2)
                  .toString();
                this.sumtotal =
                  sumtotals > 0
                    ? parseFloat(sumtotals)
                      .toFixed(2)
                      .toString()
                    : "0";
                this.sumtotalwithdraw =
                  sumtotalwithdraws > 0
                    ? parseFloat(sumtotalwithdraws)
                      .toFixed(2)
                      .toString()
                    : "0";
                this.sumprofit =
                  this.sumtotal -
                  this.sumtotalwithdraw +
                  " ( " +
                  (
                    ((this.sumtotal - this.sumtotalwithdraw) * 100) /
                    this.sumtotal
                  ).toFixed(2) +
                  "% )";
                this.secondedeposit = parseFloat(secondedeposits)
                  .toFixed(2)
                  .toString();
                this.data.rows = result_log;
                res.data.log.map(function (e) {
                  if (e.deposit != null) {
                    check_deposit += 1;
                  }
                });
                this.interest_url = res.data.tracking
                  ? res.data.tracking.interest_url === 0
                    ? "-"
                    : res.data.tracking.interest_url.toString()
                  : "-";
                this.click_url = res.data.tracking
                  ? res.data.tracking.click_url === 0
                    ? "-"
                    : res.data.tracking.click_url.toString()
                  : "-";
                this.register = res.data.log
                  ? res.data.log.length === 0
                    ? "-"
                    : res.data.log.length.toString()
                  : "-";
                this.register_deposit = res.data.log
                  ? check_deposit === 0
                    ? "-"
                    : check_deposit.toString()
                  : "-";

                this.agv_data[0] =
                  (this.click_url * 100) / this.interest_url
                    ? parseFloat(
                      (this.click_url * 100) / this.interest_url
                    ).toFixed(2)
                    : "-";
                this.agv_data[1] =
                  (this.register * 100) / this.click_url
                    ? parseFloat(
                      (this.register * 100) / this.click_url
                    ).toFixed(2)
                    : "-";
                this.agv_data[2] =
                  (this.register_deposit * 100) / this.register
                    ? parseFloat(
                      (this.register_deposit * 100) / this.register
                    ).toFixed(2)
                    : "-";
                this.doughnutChartData.datasets[0].data[2] =
                  this.register_deposit === "-" ? 0 : this.register_deposit;
                this.doughnutChartData.datasets[0].data[1] =
                  this.register === "-" ? 0 : this.register;
                this.doughnutChartData.datasets[0].data[0] =
                  parseInt(this.click_url === "-" ? 0 : this.click_url) -
                  parseInt(this.doughnutChartData.datasets[0].data[1]) -
                  parseInt(this.doughnutChartData.datasets[0].data[2]);
                let days = [];
                let amount_regis = [];
                for (let i = 6; i >= 0; i--) {
                  let check_deposit = 0;
                  let check_memberdeposit = 0;
                  let check_memberdepositsecond = 0;
                  res.data.log.map(function (e) {
                    if (
                      moment(e.date_regis).format("YYYY-MM-DD") ===
                      moment()
                        .add(-i, "days")
                        .format("YYYY-MM-DD")
                    ) {
                      check_deposit += 1;
                    }

                    if (
                      moment(e.datetime_deposit).format("YYYY-MM-DD") ===
                      moment()
                        .add(-i, "days")
                        .format("YYYY-MM-DD") &&
                      e.deposit > 0
                    ) {
                      check_memberdeposit += 1;
                    }
                    if (
                      moment(e.datetime_second).format("YYYY-MM-DD") ===
                      moment()
                        .add(-i, "days")
                        .format("YYYY-MM-DD") &&
                      e.second_deposite > 0
                    ) {
                      check_memberdepositsecond += 1;
                    }
                  });
                  // res.data.log.map(function (e) {
                  //   if (
                  //     moment(e.date_regis).format("YYYY-MM-DD") ===
                  //       moment().add(-i, "days").format("YYYY-MM-DD") &&
                  //     e.deposit > 0
                  //   ) {
                  //     check_memberdeposit += 1;
                  //   }
                  // });
                  this.barChart.data.series[0][6 - i] = check_deposit;
                  this.barChart.data.series[1][6 - i] = check_memberdeposit;
                  this.barChart.data.series[2][
                    6 - i
                  ] = check_memberdepositsecond;
                  days.push(
                    moment()
                      .add(-i, "days")
                      .format("DD")
                  );
                }
                this.barChart.data.labels = days;
              }
            });
          this.isChart = true;
        } else {
          this.isChart = false;
        }
      }
    },
    async handlereset() {
      this.date_start = "";
      this.date_end = "";
      no = 0;
      this.isChart = false;
      this.date_now = moment().format("DD-MM-YYYY");
      if (this.compaign_name) {
        this.barChart.data.labels = [];
        this.barChart.data.series[0] = [];
        this.barChart.data.series[1] = [];
        this.barChart.data.series[2] = [];
        let payload = {
          id: this.id_campaign
        };
        let user = JSON.parse(localStorage.getItem("user"));
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user
        };
        await this.$axios
          .post("/detail_report", { token: token }, { headers: headers })
          .then(async res => {
            if (res && res.data.code === 0) {
              this.result_data = res.data.tracking;
              let check_deposit = 0;
              let result_log = [];
              let firstdeposits = 0;
              let secondedeposits = 0;
              let sumtotals = 0;
              let sumtotalwithdraws = 0;

              for (const item of res.data.log) {
                firstdeposits +=
                  firstdeposits + item.deposit ? item.deposit : 0;
                secondedeposits +=
                  secondedeposits + item.second_deposite
                    ? item.second_deposite
                    : 0;
                sumtotals +=
                  sumtotals + item.total_deposit ? item.total_deposit : 0;
                sumtotalwithdraws +=
                  sumtotalwithdraws + item.withdraw ? item.withdraw : 0;
                item.profit = item.total_deposit - item.withdraw;
                result_log.push(item);
              }
              this.firstdeposit = parseFloat(firstdeposits)
                .toFixed(2)
                .toString();
              this.sumtotal =
                sumtotals > 0
                  ? parseFloat(sumtotals)
                    .toFixed(2)
                    .toString()
                  : "0";
              this.sumtotalwithdraw =
                sumtotalwithdraws > 0
                  ? parseFloat(sumtotalwithdraws)
                    .toFixed(2)
                    .toString()
                  : "0";
              this.sumprofit =
                this.sumtotal -
                this.sumtotalwithdraw +
                " ( " +
                (
                  ((this.sumtotal - this.sumtotalwithdraw) * 100) /
                  this.sumtotal
                ).toFixed(2) +
                "% )";
              this.secondedeposit = parseFloat(secondedeposits)
                .toFixed(2)
                .toString();
              this.data.rows = result_log;
              res.data.log.map(function (e) {
                if (e.deposit != null) {
                  check_deposit += 1;
                }
              });
              this.interest_url = res.data.tracking
                ? res.data.tracking.interest_url === 0
                  ? "-"
                  : res.data.tracking.interest_url.toString()
                : "-";
              this.click_url = res.data.tracking
                ? res.data.tracking.click_url === 0
                  ? "-"
                  : res.data.tracking.click_url.toString()
                : "-";
              this.register = res.data.log
                ? res.data.log.length === 0
                  ? "-"
                  : res.data.log.length.toString()
                : "-";
              this.register_deposit = res.data.log
                ? check_deposit === 0
                  ? "-"
                  : check_deposit.toString()
                : "-";

              this.agv_data[0] =
                (this.click_url * 100) / this.interest_url
                  ? parseFloat(
                    (this.click_url * 100) / this.interest_url
                  ).toFixed(2)
                  : "-";
              this.agv_data[1] =
                (this.register * 100) / this.click_url
                  ? parseFloat((this.register * 100) / this.click_url).toFixed(
                    2
                  )
                  : "-";
              this.agv_data[2] =
                (this.register_deposit * 100) / this.register
                  ? parseFloat(
                    (this.register_deposit * 100) / this.register
                  ).toFixed(2)
                  : "-";
              this.doughnutChartData.datasets[0].data[2] =
                this.register_deposit === "-" ? 0 : this.register_deposit;
              this.doughnutChartData.datasets[0].data[1] =
                this.register === "-" ? 0 : this.register;
              this.doughnutChartData.datasets[0].data[0] =
                parseInt(this.click_url === "-" ? 0 : this.click_url) -
                parseInt(this.doughnutChartData.datasets[0].data[1]) -
                parseInt(this.doughnutChartData.datasets[0].data[2]);
              let days = [];
              let amount_regis = [];
              for (let i = 6; i >= 0; i--) {
                let check_deposit = 0;
                let check_memberdeposit = 0;
                let check_memberdepositsecond = 0;
                res.data.log.map(function (e) {
                  if (
                    moment(e.date_regis).format("YYYY-MM-DD") ===
                    moment()
                      .add(-i, "days")
                      .format("YYYY-MM-DD")
                  ) {
                    check_deposit += 1;
                  }

                  if (
                    moment(e.datetime_deposit).format("YYYY-MM-DD") ===
                    moment()
                      .add(-i, "days")
                      .format("YYYY-MM-DD") &&
                    e.deposit > 0
                  ) {
                    check_memberdeposit += 1;
                  }

                  if (
                    moment(e.datetime_second).format("YYYY-MM-DD") ===
                    moment()
                      .add(-i, "days")
                      .format("YYYY-MM-DD") &&
                    e.second_deposite > 0
                  ) {
                    check_memberdepositsecond += 1;
                  }
                });
                // res.data.log.map(function (e) {
                //   if (
                //     moment(e.date_regis).format("YYYY-MM-DD") ===
                //       moment().add(-i, "days").format("YYYY-MM-DD") &&
                //     e.deposit > 0
                //   ) {
                //     check_memberdeposit += 1;
                //   }
                // });
                this.barChart.data.series[0][6 - i] = check_deposit;
                this.barChart.data.series[1][6 - i] = check_memberdeposit;
                this.barChart.data.series[2][6 - i] = check_memberdepositsecond;
                days.push(
                  moment()
                    .add(-i, "days")
                    .format("DD")
                );
              }
              this.barChart.data.labels = days;
            }
          });
        this.isChart = true;
      } else {
        this.isChart = false;
      }
    },
  },
  async mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      id: this.$route.query.id
    };
    var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + user
    };

    await this.$axios
      .post("/detail_tracking", { token: token }, { headers: headers })
      .then(async res => {
        if (
          res.data.payload &&
          res.data.payload.Web.service_name === this.$route.query.web
        ) {
          let result_series =
            res.data.payload.device_ios +
            res.data.payload.device_web +
            res.data.payload.device_android;
          let result_labels = [];
          this.persen[0] =
            res.data.payload.device_ios > 0
              ? ((res.data.payload.device_ios * 100) / result_series).toFixed(
                0
              ) + "%"
              : "0%";
          this.persen[1] =
            res.data.payload.device_web > 0
              ? ((res.data.payload.device_web * 100) / result_series).toFixed(
                0
              ) + "%"
              : "0%";
          this.persen[2] =
            res.data.payload.device_android > 0
              ? (
                (res.data.payload.device_android * 100) /
                result_series
              ).toFixed(0) + "%"
              : "0%";

          this.pieChart.data.series[0] =
            res.data.payload.device_ios > 0
              ? (res.data.payload.device_ios * 100) / result_series
              : "";
          this.pieChart.data.series[1] =
            res.data.payload.device_web > 0
              ? (res.data.payload.device_web * 100) / result_series
              : "";
          this.pieChart.data.series[2] =
            res.data.payload.device_android > 0
              ? (res.data.payload.device_android * 100) / result_series
              : "";
          this.accept_url = res.data.payload.accept_url.toString();
          this.click_url = res.data.payload.click_url.toString();
          this.platform_name = res.data.payload.Platform.platform_name.toString();
          this.channel =
            parseInt(res.data.payload.channel) === 0 ? "Line" : "Website";
          this.result_data = res.data.payload;

          this.isnull =
            this.pieChart.data.series[0].length === 0 ? (this.isnull += 1) : -1;
          this.isnull =
            this.pieChart.data.series[1].length === 0 ? (this.isnull += 1) : -1;
          this.isnull =
            this.pieChart.data.series[2].length === 0 ? (this.isnull += 1) : -1;
          this.checkIsnull = this.isnull === 3 ? true : false;
        } else {
          this.$router.push("/");
        }
      });

    await this.handleselect(this.result_data.id, this.result_data.main_campaign)
  },

};
</script>
<style lang="css" scoped>
.success-color {
  background-color: #00c851 !important;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.pull-right {
  float: right;
}
</style>
