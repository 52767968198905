<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div>
          <div class="col-sm-12">
            <template>
              <div class="row mt-3">
                <div class="col-md-3 col-lg-2 col-sm-12">
                  <l-button
                    class="mb-3 w-100 col-12 text-nowrap"
                    type="primary"
                    @click="handleadd()"
                    ><i class="fa fa-plus-circle"></i> เพิ่ม QR-Code</l-button
                  >
                </div>
              </div>
              <card title="ข้อมูล QR-Code ทั้งหมด">
                <div>
                  <div
                    class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                  >
                    <el-select
                      class="select-default mb-3"
                      style="width: 200px"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-default"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>

                    <el-input
                      type="search"
                      class="mb-3"
                      style="width: 200px"
                      placeholder="ค้นหา ชื่อลิ้งค์"
                      v-model="searchQuery"
                      aria-controls="datatables"
                    />
                  </div>
                  <div class="col-sm-12">
                    <el-table
                      class="table-striped"
                      style="width: 100%"
                      :data="queriedData"
                    >
                      <el-table-column
                        v-for="column in tableColumns"
                        :key="column.label"
                        :min-width="column.minWidth"
                        :prop="column.prop"
                        :label="column.label"
                      >
                      </el-table-column>
                      <el-table-column :min-width="100" label="QR-Code">
                        <template slot-scope="props">
                          <div>
                            <button
                              class="btn btn-info mr-2 btn-with"
                              @click="handleView(props.row.url_web)"
                            >
                              ดูรูปภาพ
                            </button>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div
                  slot="footer"
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <div class="">
                    <p class="card-category">
                      แสดง {{ from + 1 }} ถึง {{ to }} จาก {{ total }} รายการ
                    </p>
                  </div>
                  <l-pagination
                    class="pagination-no-border"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                  >
                  </l-pagination>
                </div>
              </card>
            </template>
            <mdb-modal :show="modal" @close="modal = false">
              <mdb-modal-header>
                <mdb-modal-title>รูปภาพ QR-Code</mdb-modal-title>
              </mdb-modal-header>
              <mdb-modal-body class="text-center">
                <qrcode-vue
                  class="mt-4"
                  :value="link_name"
                  :size="size"
                  level="H"
                  id="imageDIV"
                ></qrcode-vue
              ></mdb-modal-body>
              <mdb-modal-footer class="text-center">
                <mdb-btn color="danger" @click.native="modal = false"
                  >ปิดหน้าต่าง</mdb-btn
                >
                <mdb-btn color="primary" @click="saveimage()"
                  >โหลดรูปภาพ</mdb-btn
                >
              </mdb-modal-footer>
            </mdb-modal>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import {
  mdbBtn,
  mdbListGroupItem,
  mdbIcon,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody,
  mdbModalFooter
} from "mdbvue";
import moment from "moment";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
import QrcodeVue from "qrcode.vue";
import $ from "jquery";
import html2canvas from "html2canvas";
var jwt = require("jsonwebtoken");
let no = 0;
let highBound = 0;
export default {
  components: {
    QrcodeVue,
    mdbBtn,
    LPagination,
    mdbListGroupItem,
    mdbIcon,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    mdbModalFooter
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, highBound);
    },
    queriedData() {
      let result_selected = [];
      if (this.searchQuery !== "") {
        let result = this.tableData;
        result = this.fuseSearch.search(this.searchQuery);
        result.map(entry => {
          result_selected.push(entry.item);
        });
        this.tableData = result_selected;
        this.pagination.total = result.length;
        return result_selected.slice(this.from, this.to);
      } else {
        return this.users.slice(this.from, this.pagination.perPage);
      }
    },
    to() {
      highBound = this.from + this.pagination.perPage;

      if (this.total < highBound) {
        highBound = this.total == 0 ? highBound : this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length === 0 ? highBound : this.tableData.length;
    }
  },
  data() {
    return {
      hidden: false,
      modal: false,
      web: "",
      size: "250",
      link_name: "",
      img_user: "",
      renderComponent: true,
      serviceName: null,
      search: "",
      activeItem: "",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 50, 100],
        total: 0
      },
      users: [],
      searchQuery: "",
      propsToSearch: [
        "main_campaign",
        "second_campaign",
        "Platform.platform_name"
      ],
      tableColumns: [
        {
          prop: "create_date",
          label: "วันที่",
          Width: 140
        },
        {
          prop: "url_web",
          label: "ลิ้งค์เว็บไซต์",
          minWidth: 180
        },
        {
          prop: "createBy",
          label: "สร้างโดย",
          minWidth: 80
        }
      ],
      tableData: [],
      fuseSearch: []
    };
  },
  watch: {
    async $route() {
      (this.img_user = "../static/img/" + this.$route.query.web + ".png"),
        (this.web = this.$route.query.web);
      await this.$axios
        .post("/list_tracking", { web: this.$route.query.web }, this.token)
        .then(async res => {
          let result_data = [];
          if (res.data.payload[0]) {
            this.users = res.data.payload;
            this.tableData = res.data.payload;
          } else {
            this.users = [];
            this.tableData = [];
          }
        });
      this.fuseSearch = new Fuse(this.tableData, {
        keys: ["main_campaign", "second_campaign", "Platform.platform_name"]
      });
    }
  },
  methods: {
    handleView(e) {
      if (e.length > 0) {
        this.modal = true;
        this.link_name = e;
      }
    },
    handleEdit(index, row) {
      this.$router.push(
        "/edit_result?web=" + this.$route.query.web + "&id=" + row.id
      );
    },
    handleadd() {
      this.$router.push("/create_qrcode");
    },
    handleareport() {
      this.$router.push("/report?web=" + this.$route.query.web);
    },
    handleDelete(index, row) {
      Swal.fire({
        title: "ยืนยันการลบข้อมูล",
        text: "คุณต้องการลบข้อมูล : ",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          Swal.fire("Poof! Your imaginary file has been deleted!", {
            icon: "success"
          });
        } else {
          Swal.fire("Your imaginary file is safe!");
        }
      });
      Swal.fire({
        title: "ยืนยันการลบข้อมูล?",
        text: "ต้องการลบข้อมูล : " + row.main_campaign + " หรือไม่",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบข้อมูล",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          await this.$axios
            .post(
              "/delete_list",
              {
                id: row.id
              },
              this.token
            )
            .then(async res => {
              let result_data = [];
              if (res.data.code == 0) {
                Swal.fire("" + res.data.msg).then(async res => {
                  location.reload();
                });
              } else {
                Swal.fire("" + res.data.msg).then(async res => {
                  location.reload();
                });
              }
            });
        }
      });
    },
    saveimage() {
      var canvas = document.getElementById("imageDIV");
      var dataURL = canvas.childNodes[0].toDataURL("image/png", 1.0);
      var a = document.createElement("a");
      a.href = dataURL;
      a.download = "qrpay_.png";
      a.click();
    }
  },
  async mounted() {
    let user = JSON.parse(localStorage.getItem("user"));
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + user
    };
    await this.$axios
      .get("/list_qrcode", { headers: headers })
      .then(async res => {
        let result_data = [];
        if (res.data.payload[0]) {
          this.users = res.data.payload;
          this.tableData = res.data.payload;
        } else {
          this.users = [];
          this.tableData = [];
        }
      });
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["main_campaign", "second_campaign", "Platform.platform_name"]
    });
  }
};
</script>
<style>
.row-center {
  display: -webkit-inline-box;
}
.btn-action {
  padding: 8px;
  margin-right: 5px;
}
.mdb-datatable-header-hover-icon {
  display: none;
  left: 0.1rem !important;
  top: 1.25rem !important;
}
.fas {
  font-weight: 900 !important;
  font-family: Font awesome\5 Free !important;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-arrow-up:before {
  color: black !important;
  display: table-column-group !important;
}
.fa-arrow-down:before {
  color: black !important;
  display: table-column-group !important;
}
.sorting {
  cursor: pointer !important;
  font-size: 15px !important;
  color: #000 !important;
}
.el-table thead {
  color: #333333;
  font-weight: 500;
}
.el-input {
  width: 200px !important;
  max-width: 200px;
}
.styleimg {
  width: 110px;
}
.sit {
  display: flex;
  margin-left: 15px;
  color: black;
  font-weight: 600;
}
.text-group {
  margin-left: -61px;
  padding-top: 30px;
}
.btn-with {
  width: 130px !important;
}
</style>
