<template>
  <div class="row">
    <div class="col-12">
      <card>
        <div>
          <div class="col-sm-12">
            <template>
              <div class="row mb-3">
                <div class="col-sm-12 col-md-3">
                  <img :src="img_user" alt="avatar" class="styleimg" />
                  <a class="navbar-brand float-rigth"
                    ><p class="sit">
                      {{
                        this.$route.query.web
                          ? "เว็บไซต์ : " + this.$route.query.web
                          : null
                      }}
                    </p></a
                  >
                </div>
              </div>
              <div class="row">
                <div class="mb-3">
                  <div class="align-self-center pl-3">ข้อมูลจากวันที่</div>
                  <div class="align-self-center col-sm-12 col-md-2">
                    <date-picker
                      class="input-date"
                      valueType="format"
                      lang="en"
                      v-model="date_start"
                    ></date-picker>
                  </div>
                </div>
                <div class="mb-3">
                  <div class="align-self-center pl-3">ข้อมูลถึงวันที่</div>
                  <div class="align-self-center col-sm-12 col-md-2">
                    <date-picker
                      class="input-date"
                      valueType="format"
                      lang="en"
                      v-model="date_end"
                    ></date-picker>
                  </div>
                </div>
                <div class="col-sm-6 col-md-4 mt-2 ml-4 text-left">
                  <l-button
                    class="text-nowrap"
                    type="success"
                    @click="handleFind()"
                  >
                    ค้นหา</l-button
                  >
                  <l-button
                    class="text-nowrap"
                    type="info"
                    @click="handlereset()"
                  >
                    ล้างข้อมูล</l-button
                  >
                </div>
              </div>
              <div>
                <p v-if="textselect">
                  <b class="ml-3" style="color: red; font-size: 13px">
                    * เริ่มเก็บสถิติคนดูและคนที่สนใจ ตั้งแต่วันที่ 26 เมษายน
                    2564 เป็นต้นมา</b
                  >
                </p>
                <div class="card" v-if="textselect">
                  <div class="card-body">
                    {{ textselect }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-xl-3 col-md-6 col-xs-12 col-sm-6"
                  v-for="item in platform"
                  :key="item.platform"
                >
                  <stats-card
                    :title="item.platform.toString()"
                    :interest="item.interest_url.toString()"
                    :click="item.click_url.toString()"
                    :accept="item.accept_url.toString()"
                    :deposit="item.deposit.toString()"
                  >
                    <div slot="header" class="icon-warning">
                      <i class="fa fa-bar-chart text-warning"></i>
                    </div>
                  </stats-card>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-3 col-lg-2 col-sm-12">
                  <l-button
                    class="mb-3 w-100 col-12 text-nowrap"
                    type="primary"
                    @click="handleadd()"
                    ><i class="fa fa-plus-circle"></i> เพิ่มโฆษณา</l-button
                  >
                </div>
                <div class="col-md-4 col-lg-2 col-sm-12">
                  <l-button
                    class="mb-3 col-12 text-nowrap"
                    type="primary"
                    @click="handleareport()"
                  >
                    รายงานสรุปโฆษณา</l-button
                  >
                </div>
              </div>
              <card title="ข้อมูลการโฆษณาทั้งหมด">
                <div>
                  <div
                    class="
                      col-12
                      d-flex
                      justify-content-center justify-content-sm-between
                      flex-wrap
                    "
                  >
                    <el-select
                      class="select-default mb-3"
                      style="width: 200px"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-default"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>

                    <el-input
                      type="search"
                      class="mb-3"
                      style="width: 200px"
                      placeholder="ค้นหาแคมเปญ แฟลตฟอร์ม"
                      v-model="searchQuery"
                      aria-controls="datatables"
                    />
                  </div>
                  <div class="col-sm-12">
                    <el-table
                      class="table-striped"
                      style="width: 100%"
                      :data="queriedData"
                    >
                      <el-table-column
                        v-for="column in tableColumns"
                        :key="column.label"
                        :min-width="column.minWidth"
                        :prop="column.prop"
                        :label="column.label"
                      >
                      </el-table-column>
                      <el-table-column :min-width="185" label="เพิ่มเติม">
                        <template slot-scope="props">
                          <div>
                            <button
                              class="btn btn-warning mr-2 btn-with"
                              v-clipboard:copy="props.row.short_url"
                              v-clipboard:success="handleCopy"
                              @click="handleCopy(props.row.short_url)"
                            >
                              ก็อปลิ้งค์
                            </button>
                            <button
                              class="btn btn-success mr-2 btn-with"
                              @click="handleView(props.$index, props.row)"
                            >
                              รายละเอียด
                            </button>
                            <button
                              class="mr-2 btn btn-info btn-with"
                              @click="handleEdit(props.$index, props.row)"
                            >
                              แก้ไข
                            </button>
                            <button
                              class="mr-2 btn btn-danger btn-with"
                              @click="handleDelete(props.$index, props.row)"
                            >
                              ลบข้อมูล
                            </button>
                          </div>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
                <div
                  slot="footer"
                  class="
                    col-12
                    d-flex
                    justify-content-center justify-content-sm-between
                    flex-wrap
                  "
                >
                  <div class="">
                    <p class="card-category">
                      แสดง {{ from + 1 }} ถึง {{ to }} จาก {{ total }} รายการ
                    </p>
                  </div>
                  <l-pagination
                    class="pagination-no-border"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                  >
                  </l-pagination>
                </div>
              </card>
            </template>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination, StatsCard } from "src/components/index";
import { mdbBtn, mdbListGroupItem, mdbIcon } from "mdbvue";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Fuse from "fuse.js";
import Swal from "sweetalert2";
var jwt = require("jsonwebtoken");

let no = 0;
let highBound = 0;
export default {
  components: {
    DatePicker,
    mdbBtn,
    LPagination,
    mdbListGroupItem,
    mdbIcon,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    StatsCard,
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, highBound);
    },
    queriedData() {
      let result_selected = [];
      if (this.searchQuery !== "") {
        let result = this.tableData;
        result = this.fuseSearch.search(this.searchQuery);
        result.map((entry) => {
          result_selected.push(entry.item);
        });
        this.tableData = result_selected;
        this.pagination.total = result.length;
        return result_selected.slice(this.from, this.to);
      } else {
        this.tableData = this.lengthtable;
        this.pagination.total = this.lengthtable.length;
        return this.users.slice(
          this.from,
          this.pagination.perPage * (this.pagination.currentPage - 1) +
            this.pagination.perPage
        );
      }
    },
    to() {
      highBound = this.from + this.pagination.perPage;

      if (this.total < highBound) {
        highBound = this.total == 0 ? highBound : this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length === 0 ? highBound : this.tableData.length;
    },
  },
  data() {
    return {
      textselect: "",
      date_start: "",
      date_end: "",
      web: "",
      linkCopy: "",
      img_user: "",
      renderComponent: true,
      serviceName: null,
      search: "",
      activeItem: "",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [10, 20, 50, 100],
        total: 0,
      },
      users: [],
      searchQuery: "",
      propsToSearch: ["main_campaign"],
      tableColumns: [
        {
          prop: "createdAt",
          label: "วันที่",
          Width: 140,
        },
        {
          prop: "main_campaign",
          label: "แคมเปญหลัก",
          minWidth: 140,
        },
        {
          prop: "second_campaign",
          label: "แคมเปญย่อย",
          minWidth: 120,
        },
        {
          prop: "Platform.platform_name",
          label: "แพลตฟอร์ม",
          minWidth: 105,
        },
        {
          prop: "short_url",
          label: "ลิ้งการตลาด",
          minWidth: 210,
        },
      ],
      tableData: [],
      lengthtable: [],
      fuseSearch: [],
      platform: [],
    };
  },
  watch: {
    async $route() {
      this.textselect = "";
      this.date_start = "";
      this.date_end = "";
      (this.img_user = "../static/img/" + this.$route.query.web + ".png"),
        (this.web = this.$route.query.web);
      let id_user = JSON.parse(localStorage.getItem("user"));
      let payload = {
        web: this.$route.query.web,
      };
      var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
      let headers = {
        "Content-Type": "application/json",
        Authorization: "authorization " + id_user,
      };
      await this.$axios
        .post("/list_tracking", { token: token }, { headers: headers })
        .then(async (res) => {
          let result_data = [];
          this.platform = [];
          if (res.data.payload[0]) {
            this.platform = res.data.platform;
            this.users = res.data.payload;
            this.tableData = res.data.payload;
            this.lengthtable = res.data.payload;
          } else {
            this.users = [];
            this.tableData = [];
          }
        });
      this.fuseSearch = new Fuse(this.tableData, {
        keys: ["main_campaign"],
      });
    },
  },
  methods: {
    async handlereset() {
      this.textselect = "";
      this.date_start = "";
      this.date_end = "";
      let id_user = JSON.parse(localStorage.getItem("user"));
      let payload = {
        web: this.$route.query.web,
      };
      var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
      let headers = {
        "Content-Type": "application/json",
        Authorization: "authorization " + id_user,
      };
      await this.$axios
        .post("/list_tracking", { token: token }, { headers: headers })
        .then(async (res) => {
          let result_data = [];
          this.platform = [];
          if (res.data.payload[0]) {
            this.platform = res.data.platform;
            this.users = res.data.payload;
            this.tableData = res.data.payload;
            this.lengthtable = res.data.payload;
          } else {
            this.users = [];
            this.tableData = [];
          }
        });
      this.fuseSearch = new Fuse(this.tableData, {
        keys: ["main_campaign"],
      });
      this.textselect = "";
    },
    async handleFind() {
      if (
        this.date_start != "" &&
        this.date_start != null &&
        this.date_end != "" &&
        this.date_end != null
      ) {
        this.date_start = moment(this.date_start).format("YYYY-MM-DD");
        this.date_end = moment(this.date_end).format("YYYY-MM-DD");
        var start = moment(this.date_start).format("YYYY-MM-DD");
        var end = moment(this.date_end).format("YYYY-MM-DD");

        if (start > end) {
          this.date_end = start;
          this.date_start = end;
        }
        this.img_user = "../static/img/" + this.$route.query.web + ".png";
        let id_user = JSON.parse(localStorage.getItem("user"));
        let payload = {
          web: this.$route.query.web,
          date_start: this.date_start,
          date_end: this.date_end,
        };
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + id_user,
        };
        await this.$axios
          .post("/list_trackingfind", { token: token }, { headers: headers })
          .then(async (res) => {
            let result_data = [];
            if (res.data.payload[0]) {
              this.platform = res.data.platform;
            } else {
              this.users = [];
              this.tableData = [];
            }
          });
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ["main_campaign"],
        });
        this.textselect =
          "แสดงข้อมูลวันที่ : " + this.date_start + " ถึง : " + this.date_end;
      } else {
        let id_user = JSON.parse(localStorage.getItem("user"));
        let payload = {
          web: this.$route.query.web,
        };
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + id_user,
        };
        await this.$axios
          .post("/list_tracking", { token: token }, { headers: headers })
          .then(async (res) => {
            this.platform = [];
            let result_data = [];
            if (res.data.payload[0]) {
              this.platform = res.data.platform;
              this.users = res.data.payload;
              this.tableData = res.data.payload;
              this.lengthtable = res.data.payload;
            } else {
              this.users = [];
              this.tableData = [];
            }
          });
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ["main_campaign"],
        });
        this.textselect = "";
      }
    },
    handleCopy(link) {
      this.linkCopy = link;
      Swal.fire({
        title: "คัดลอกลิ้งเรียบร้อย",
        icon: "success",
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: true,
      });
      this.linkCopy = "";
    },
    handleView(index, row) {
      this.$router.push(
        "/detail_result?web=" + this.$route.query.web + "&id=" + row.id
      );
    },
    handleEdit(index, row) {
      this.$router.push(
        "/edit_result?web=" + this.$route.query.web + "&id=" + row.id
      );
    },
    handleadd() {
      this.$router.push("/create_result?web=" + this.$route.query.web);
    },
    handleareport() {
      this.$router.push("/report?web=" + this.$route.query.web);
    },
    handleDelete(index, row) {
      Swal.fire({
        title: "ยืนยันการลบข้อมูล",
        text: "คุณต้องการลบข้อมูล : ",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          Swal.fire("Poof! Your imaginary file has been deleted!", {
            icon: "success",
          });
        } else {
          Swal.fire("Your imaginary file is safe!");
        }
      });
      Swal.fire({
        title: "ยืนยันการลบข้อมูล?",
        text: "ต้องการลบข้อมูล : " + row.main_campaign + " หรือไม่",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบข้อมูล",
        cancelButtonText: "ยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let user = JSON.parse(localStorage.getItem("user"));
          let payload = {
            id: row.id,
          };
          var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
          let headers = {
            "Content-Type": "application/json",
            Authorization: "authorization " + user,
          };
          await this.$axios
            .post(
              "/delete_list",
              {
                token: token,
              },
              { headers: headers }
            )
            .then(async (res) => {
              let result_data = [];
              if (res.data.code == 0) {
                Swal.fire("" + res.data.msg).then(async (res) => {
                  location.reload();
                });
              } else {
                Swal.fire("" + res.data.msg).then(async (res) => {
                  location.reload();
                });
              }
            });
        }
      });
    },
  },
  async mounted() {
    this.textselect = "";
    this.date_start = "";
    this.date_end = "";
    this.img_user = "../static/img/" + this.$route.query.web + ".png";
    let id_user = JSON.parse(localStorage.getItem("user"));
    let payload = {
      web: this.$route.query.web,
    };
    var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "authorization " + id_user,
    };
    await this.$axios
      .post("/list_tracking", { token: token }, { headers: headers })
      .then(async (res) => {
        this.platform = [];
        let result_data = [];
        if (res.data.payload[0]) {
          this.platform = res.data.platform;
          this.users = res.data.payload;
          this.tableData = res.data.payload;
          this.lengthtable = res.data.payload;
        } else {
          this.users = [];
          this.tableData = [];
        }
      });
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["main_campaign"],
    });
  },
};
</script>
<style>
.row-center {
  display: -webkit-inline-box;
}
.btn-action {
  padding: 8px;
  margin-right: 5px;
}
.mdb-datatable-header-hover-icon {
  display: none;
  left: 0.1rem !important;
  top: 1.25rem !important;
}
.fas {
  font-weight: 900 !important;
  font-family: Font awesome\5 Free !important;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-arrow-up:before {
  color: black !important;
  display: table-column-group !important;
}
.fa-arrow-down:before {
  color: black !important;
  display: table-column-group !important;
}
.sorting {
  cursor: pointer !important;
  font-size: 15px !important;
  color: #000 !important;
}
.el-table thead {
  color: #333333;
  font-weight: 500;
}
.el-input {
  width: 200px !important;
  max-width: 200px;
}
.styleimg {
  width: 110px;
  height: 100px;
}
.sit {
  display: flex;
  margin-left: 15px;
  color: black;
  font-weight: 600;
}
.text-group {
  margin-left: -61px;
  padding-top: 30px;
}
.btn-with {
  width: 130px !important;
}
</style>
