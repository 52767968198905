<template>
  <nav class="navbar navbar-expand-lg ">
    <div class="container-fluid">
      <div class="navbar-minimize">
        <button
          class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block"
          @click="minimizeSidebar"
        >
          <i
            :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'"
          ></i>
        </button>
      </div>
      <!-- <a class="navbar-brand float-left">{{
        this.$route.query.web ? "GROUP : " + this.$route.query.web : null
      }}</a> -->
      <!-- <a class="navbar-brand float-rigth">{{
        this.$route.query.web ? "เว็บไซต์ : " + this.$route.query.web : null
      }}</a> -->
      <button
        type="button"
        class="navbar-toggler navbar-toggler-right"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-expanded="false"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
    </div>
  </nav>
</template>
<script>
export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      activeNotifications: false
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    }
  }
};
</script>
<style>
.navbar .navbar-brand {
  font-weight: 400;
  margin: 5px 0px;
  font-size: 18px;
  color: #ffffff !important;
}
.navbar-expand-lg {
  background-color: #00346b !important;
}
.btn.btn-outline {
  color: #ffffff !important;
}
.navbar-toggler {
  margin-top: -18px !important;
}
.burger-lines {
  background-color: #ffffff !important;
}
</style>
