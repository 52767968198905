<template>
  <div class="row">
    <div class="col-md-12">
      <card>
        <template slot="header">
          <button class="btn btn-info" @click="handleEdit()">
            <i class="fa fa-step-backward"></i> ย้อนกลับ
          </button>
          <h4 class="card-title mt-4">เพิ่มข้อมูลแพลตฟอร์ม</h4>
        </template>
        <div>
          <form class="form-horizontal">
            <div class="row">
              <div class="col-md-4">
                <fg-input
                  label="วันที่"
                  type="text"
                  :value="createdAt"
                  disabled
                />
              </div>
              <div class="col-md-4">
                <fg-input
                  label="แพลตฟอร์ม"
                  type="text"
                  v-model="platform_name"
                />
              </div>
              <div class="col-md-4">
                <label> สถานะการใช้งาน</label><br />
                <el-select
                  label="สถานะการใช้งาน"
                  class="select-primary"
                  size="large"
                  placeholder="เลือกสถานะการใช้งาน"
                  v-model="selects.simple"
                >
                  <el-option
                    v-for="option in selects.countries"
                    class="select-danger"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row"></div>
            <div class="row">
              <div class="col-md-12">
                <fg-input label="หมายเหตุ" type="text" v-model="comment" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 text-center">
                <l-button type="success" wide @click="handleSave()"
                  >บันทึกข้อมูล</l-button
                >
              </div>
            </div>
          </form>
        </div>
      </card>
      <!-- end card -->
    </div>
  </div>
</template>
<script>
import { Checkbox, Radio, ChartCard, StatsCard } from "src/components/index";
import { Select, Option } from "element-ui";
import Swal from "sweetalert2";
import moment from "moment";
var jwt = require("jsonwebtoken");
export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    Checkbox,
    Radio,
    ChartCard,
    StatsCard
  },
  data() {
    return {
      selects: {
        simple: "",
        countries: [
          {
            label: "เปิดใช้งาน",
            value: 1
          },
          {
            label: "ยกเลิกใช้งาน",
            value: 2
          }
        ]
      },
      comment: "",
      platform_name: "",
      createdAt: ""
    };
  },
  methods: {
    handleEdit() {
      this.$router.push("/show_plateform");
    },
    async handleSave() {
      if (this.platform_name && this.selects.simple) {
        let payload = {
          platform_name: this.platform_name,
          status: this.selects.simple,
          comment: this.comment
        };
        let user = JSON.parse(localStorage.getItem("user"));
        var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
        let headers = {
          "Content-Type": "application/json",
          Authorization: "authorization " + user
        };
        await this.$axios
          .post("/create_platform", { token: token }, { headers: headers })
          .then(res => {
            if (res.data.code === 0) {
              Swal.fire({
                title: res.data.msg,
                icon: "success",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true
              });
            } else {
              Swal.fire({
                title: res.data.msg,
                icon: "error",
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: true
              });
            }
          })
          .catch(() => {});
      } else {
        Swal.fire({
          title: "กรอกข้อมูลให้ครบ",
          icon: "error",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: true
        });
      }
    }
  },
  async mounted() {
    this.createdAt = moment().format("YYYY-MM-DD HH:mm:ss");
  }
};
</script>
<style lang="css" scoped>
.success-color {
  background-color: #00c851 !important;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.pull-right {
  float: right;
}
</style>
