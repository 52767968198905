<template>
  <a href="/singout" class="d-block singout ml-2 ">
    <i class="nc-icon nc-button-power" style="font-size: 28px;"></i>
    <p @click="singout" style="margin-left:47px;    margin-top: -28px;">
      ออกจากระบบ
    </p>
  </a>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  components: {},
  computed: {
    ...mapGetters({
      isLogin: "isLogin",
      user: "user"
    })
  },
  methods: {
    ...mapActions({
      storelogout: "logout"
    }),
    singout() {
      localStorage.clear();
      localStorage.removeItem("user");
      localStorage.removeItem("isLogin");
      this.storelogout();
      this.$router.push("/login");
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    }
  },
  async mounted() {
    if (localStorage.getItem("isLogin") == null) {
      localStorage.clear();
      localStorage.removeItem("user");
      localStorage.removeItem("isLogin");
      this.storelogout();
      this.$router.push("/login");
    }
  }
};
</script>
<style scoped>
.singout {
  text-align: initial;
  margin: 5px 15px 0px 10px !important;
  background: border-box !important;
  padding: 10px 15px !important;
  border-width: 0px !important;
  height: 51px !important;
  border-color: #3c3c3c !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 90%;
}
.singout:hover {
  border-radius: 4px !important;
  margin: 5px 15px 0px 10px !important;
  width: 90%;
  background-color: rgb(133, 133, 133, 0.3) !important;
}
</style>
