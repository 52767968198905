<template>
  <card class="card-stats">
    <div class="row">
      <div class="col-4" v-if="$slots.header">
        <div class="icon-big text-center">
          <slot name="header"></slot>
        </div>
      </div>
      <div class="col-8" v-if="$slots.content || title">
        <div class="numbers">
          <slot name="content">
            <p v-if="subTitle" class="card-category">{{ subTitle }}</p>
            <h4
              v-if="title"
              class="card-title"
              style="font-size: 22px; font-weight: 600"
            >
              {{ title }}
            </h4>
            <p
              v-if="interest"
              class="card-category"
              style="color: #2700fd; font-weight: 600 !important"
            >
              คนดูโฆษณา : {{ interest }}
            </p>
            <p
              v-if="click"
              class="card-category"
              style="
                color: #f56c6c;
                font-weight: 600 !important;
                font-size: 13px;
              "
            >
              คนที่สนใจ : {{ click }}
            </p>
            <p
              v-if="accept"
              class="card-category"
              style="
                color: #03bb00;
                font-weight: 600 !important;
                font-size: 13px;
              "
            >
              คนที่สมัคร : {{ accept }}
            </p>
            <p
              v-if="deposit"
              class="card-category"
              style="
                color: #00a6cc;
                font-weight: 600 !important;
                font-size: 13px;
                padding-bottom: 6px;
              "
            >
              สมัครและฝาก : {{ deposit }}
            </p>
          </slot>
        </div>
      </div>
    </div>
    <div class="stats" slot="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </card>
</template>
<script>
import Card from "./Card.vue";
export default {
  name: "stats-card",
  components: {
    Card
  },
  props: {
    title: [String, Number],
    interest: String,
    accept: String,
    click: String,
    subTitle: String,
    deposit: String
  }
};
</script>
<style></style>
