<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar>
      <user-menu></user-menu>
      <template slot-scope="props" slot="links">
        <sidebar-item :link="{ name: 'ABA GROUP', icon: 'nc-icon nc-grid-45' }">
          <sidebar-item
            v-for="item in aba_group"
            @click.native="clickmenu(item.service_name)"
            :class="item.service_name == name && 'active-select'"
            :key="item.message"
            :link="{
              name: item.service_name,
              path: '/redirect?web=' + item.service_name + '&group=ABA-GROUP'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{ name: 'AUTO GROUP', icon: 'nc-icon nc-grid-45' }"
        >
          <sidebar-item
            v-for="item in auto_group"
            @click.native="clickmenu(item.service_name)"
            :class="item.service_name == name && 'active-select'"
            :key="item.message"
            :link="{
              name: item.service_name,
              path: '/redirect?web=' + item.service_name + '&group=AUTO-GROUP'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{ name: 'ABA BACARAT', icon: 'nc-icon nc-grid-45' }"
        >
          <sidebar-item
            v-for="item in aba_bacarat"
            @click.native="clickmenu(item.service_name)"
            :class="item.service_name == name && 'active-select'"
            :key="item.message"
            :link="{
              name: item.service_name,
              path: '/redirect?web=' + item.service_name + '&group=ABA-BACARAT'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: 'BCR GROUP', icon: 'nc-icon nc-grid-45' }">
          <sidebar-item
            v-for="item in bcr_group"
            @click.native="clickmenu(item.service_name)"
            :class="item.service_name == name && 'active-select'"
            :key="item.message"
            :link="{
              name: item.service_name,
              path: '/redirect?web=' + item.service_name + '&group=BCR-GROUP'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{ name: 'OTHER', icon: 'nc-icon nc-grid-45' }">
          <sidebar-item
            v-for="item in other"
            @click.native="clickmenu(item.service_name)"
            :class="item.service_name == name && 'active-select'"
            :key="item.message"
            :link="{
              name: item.service_name,
              path: '/redirect?web=' + item.service_name + '&group=OTHER'
            }"
          ></sidebar-item>
        </sidebar-item>
        <hr class="hr" />
        <sidebar-item
          v-if="rule"
          :link="{
            name: 'ระบบ QR CODE',
            icon: 'nc-icon nc-bullet-list-67',
            path: '/qr_code'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="rule"
          :link="{
            name: 'จัดการแพลตฟอร์ม',
            icon: 'nc-icon nc-bullet-list-67',
            path: '/show_plateform'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="rule"
          :link="{
            name: 'จัดการเว็บไซต์',
            icon: 'nc-icon nc-bullet-list-67',
            path: '/show_site'
          }"
        ></sidebar-item>
        <sidebar-item
          v-if="rule"
          :link="{
            name: 'จัดการพนักงาน',
            icon: 'nc-icon nc-bullet-list-67',
            path: '/member'
          }"
        ></sidebar-item>
        <a @click="singout" class="d-block singout ml-2 ">
          <i class="nc-icon nc-button-power" style="font-size: 28px;"></i>
          <p style="margin-left:47px;    margin-top: -28px;">
            ออกจากระบบ
          </p>
        </a>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import UserMenu from "./UserMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
var jwt = require("jsonwebtoken");

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    UserMenu
  },
  data() {
    return {
      image_profile: "../static/img/default-avatar.png",
      aba_group: [],
      auto_group: [],
      aba_bacarat: [],
      bcr_group: [],
      other: [],
      active: false,
      name: null,
      rule: ""
    };
  },
  computed: {
    ...mapGetters({
      isLogin: "isLogin",
      user: "user",
      rule_user: "rule_user"
    })
  },
  methods: {
    async singout() {
      let id_user = JSON.parse(localStorage.getItem("user"));
      let payload = {
        ObjectID: id_user.ObjectID
      };
      var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
      let headers = {
        "Content-Type": "application/json",
        Authorization: "authorization " + id_user
      };
      await this.$axios
        .post("/logout", { token: token }, { headers: headers })
        .then(async res => {
          if (res && res.data) {
            localStorage.clear();
            localStorage.removeItem("user");
            localStorage.removeItem("isLogin");
            this.$router.push("/login");
          }
        });
    },
    clickmenu(name) {
      this.name = name;
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("main-panel");
        docClasses.add("perfect-scrollbar-on");
      } else {
        docClasses.add("perfect-scrollbar-off");
      }
    }
  },
  async mounted() {
    if (
      !localStorage.getItem("isLogin") ||
      localStorage.getItem("isLogin") === null
    ) {
      localStorage.clear();
      localStorage.removeItem("user");
      localStorage.removeItem("isLogin");
      this.$router.push("/login");
    } else if (
      parseInt(localStorage.getItem("timeout")) <
      parseInt(moment().format("YYYYMMDDHHmm"))
    ) {
      let id_user = JSON.parse(localStorage.getItem("user"));
      let payload = {
        ObjectID: id_user.ObjectID
      };
      var token = jwt.sign(payload, "ABAsecretKeywweqwlinkmanager");
      let headers = {
        "Content-Type": "application/json",
        Authorization: "authorization " + id_user
      };
      await this.$axios
        .post("/logout", { token: token }, { headers: headers })
        .then(async res => {
          if (res && res.data) {
            localStorage.clear();
            localStorage.removeItem("user");
            localStorage.removeItem("isLogin");
            this.$router.push("/login");
          }
        });
    } else {
      let rule = JSON.parse(localStorage.getItem("user"));
      let username = jwt.verify(rule, "ABAsecretKeywweqwlinkmanager");
      localStorage.setItem("rule_user", username.result[0].RoleOID);
      this.rule = localStorage.getItem("rule_user") === "2" ? true : false;
      let headers = {
        "Content-Type": "application/json",
        Authorization: "authorization " + rule
      };
      await this.$axios
        .get("/list_service", {
          headers: headers
        })
        .then(async res => {
          await res.data.payload.filter(word => {
            if (word.status === 1) {
              if (word.group.toString() === "ABA-GROUP") {
                this.aba_group.push(word);
              } else if (word.group.toString() === "AUTO-GROUP") {
                this.auto_group.push(word);
              } else if (word.group.toString() === "ABA-BACARAT") {
                this.aba_bacarat.push(word);
              } else if (word.group.toString() === "BCR-GROUP") {
                this.bcr_group.push(word);
              } else if (word.group.toString() === "OTHER") {
                this.other.push(word);
              }
            }
          });
        });
      this.initScrollbar();
    }
  }
};
</script>
<style lang="css" scoped>
.active-select {
  background: rgba(255, 255, 255, 0.13);
}
.hr {
  background-color: #fff;
}
.singout {
  text-align: initial;
  margin: 5px 15px 0px 10px !important;
  background: border-box !important;
  padding: 10px 15px !important;
  border-width: 0px !important;
  height: 51px !important;
  border-color: #3c3c3c !important;
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 90%;
}
.singout:hover {
  border-radius: 4px !important;
  margin: 5px 15px 0px 10px !important;
  width: 90%;
  background-color: rgb(133, 133, 133, 0.3) !important;
}
.flex-img {
  width: 34px;
}
.menu-img {
  background-image: "../static/img/default-avatar.png" !important;
}
</style>
